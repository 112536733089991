.flex-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.navbar {
    flex: 1 1 0;
    max-height: 50px;
    background-color: green;
}

.content {
    display: flex;
    flex-direction: row;
    flex: 1 1 0;
}

.content .sidebar {
    flex: 1 1 0;
    min-height: 0px;
    max-width: 150px;
    background-color: red;
}

.content .main-content {
    flex: 1 1 0;
    min-height: 0px;
    display: flex;
}

.footer {
    flex: 1 1 0;
    max-height: 50px;
}