.images-container {
  display: flex;
  /* justify-content: space-evenly; */
  gap: 3rem;
  padding: 40px;
  background-color: #DFFFD7;
}

.note {
  font-size: 17px;
  margin-bottom: 15px;

}

.content-text>p {
  font-size: 17px;
  line-height: 1.6;
  margin-bottom: 15px;
}

.img-box {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  background-color: white;
}

.img-box>.image {
  height: 250px;
  width: 250px;
}

.img-box>.image>img {
  height: 100%;
  width: 100%;
}

.img-box>.img-text {
  margin-bottom: 60px;
  font-size: 20px;
  font-weight: 600;
  border-top: .5 solid black
}


.imageDiv {
  width: 50vw;
  height: 45vh;
  margin-left: 10vw;
}

.firstImg {
  background-position: 'top';
  background-size: 'cover';
  background-repeat: 'no-repeat';
}

.multiselectContainer {
  background-color: white;
  border-color: white;
  color: rgb(15, 15, 15);
  /* width: 15.6Vw!important; */
  border-radius: '14px';
  border-color: '#fff';
  padding: '5px';

}

/* .searchBox { 
        border: none;
        font-size: 10px;
        min-height: 50px;
      } */
/* .inputField{
      background-color: blue;
     } */
.optionContainer {
  background-color: white;


}

.chip.singleChip {
  font-size: 16px !important;
  color: black;
  padding: 0px !important;
}

::placeholder {
  color: #C2C2C2 !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .multiselectContainer {
    width: 30.9Vw !important;
  }

  .inputForm {
    width: 30.9Vw !important;
  }

  .banner-image-question {
    height: 80vh;
    width: 50vh;
    border-radius: 20px;
    box-shadow: 10px 10px 10px gray;
    margin: 1rem;
  }

}

@media only screen and (max-width: 600px) {
  .banner-image-question {
    height: 50vh;
    width: 40vh;
    border-radius: 20px;
    box-shadow: 10px 10px 10px gray;
    margin: 1rem;
  }

  .tagline-para-home {
    font-size: 16px;
  }
}




/*       
  @media (max-width: 1500px) {
    .multiselectContainer{
      width: 13.9Vw!important;   }
  }

  @media (max-width: 1000px) {
    .multiselectContainer{
      width: 13.9Vw!important;   }

  }
  @media (max-width: 900px) {
    .multiselectContainer{
      width: 13.9Vw!important;    }
  }
  @media (max-width: 800px) {

    .multiselectContainer{
      width: 24.9Vw!important;    }
  }
 
  
  @media (max-width: 750px) {
    .multiselectContainer{
      width: 32.2Vw!important; 
    
    }
   
    
     
  } */