* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
}

html {
  min-height: 100%;
}

body {
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 'center';
  background-size: cover;
  height: 100vh;
}

/*header css*/
.Header_headerContainer {
  height: 110px;
  background: #0072bc;
  padding-left: 30px;
  /* margin-bottom: 10px; */

}

.countiescare-bg{
  background: #ffffff !important;
}

.Header_headerContainer .Header_nav {
  max-width: 100%;
  /* flex-flow: row nowrap;
  margin: 0 auto 2rem;
  justify-content: space-between;
  align-items: center; */
  /* margin-left: 12rem; */
}

.Header_headerContainer .Header_nav .Header_logo {
  height: calc(110px / 2);
}

@media screen and (min-width: 768px) {
  .Header_headerContainer .Header_nav .Header_logo {
    height: 110px;
  }
}

/*end header css */

/* Main Container */
.container {
  /* font-family: "Merienda One", cursive; */
  font-family: aileron, sans-serif;
  width: 100%;
  /* min-height: 100vh; */
  position: relative;
  text-align: center;
}

/* NAV */

/* NAV HOVER EFFECT */
.hvr-underline-from-center {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  /* box-shadow: 0 0 1px #1980AB; */
  position: relative;
  overflow: hidden;
}

.hvr-underline-from-center:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 51%;
  right: 51%;
  bottom: 0;
  background: #5FAE5F;

  height: 3px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.hvr-underline-from-center:hover:before,
.hvr-underline-from-center:focus:before,
.hvr-underline-from-center:active:before {
  left: 0;
  right: 0;
}

.web-title {
  margin: 20px;
  text-shadow: 2px 4px 8px white;
  font-variant: small-caps;
  font-size: 200%;
  color: #fff;
}

.nav-container {
  display: flex;
  background: #fff;
  justify-content: center;
  margin-bottom: 20px;
  margin: 0.5rem;
}

.nav-link {
  text-decoration: none;
  color: #fff;
  margin: 0 10px;
  padding: 10px;
  font-variant: small-caps;
}

.logo {
  height: 60px;
  position: absolute;
  left: 30px;
}

.active {
  text-decoration: none;
  /* color: #fff; */
  /* border-bottom: 3px solid #fff; */
  font-variant: small-caps;
}

.nav-cart {
  height: 35px;
  margin: 0;
  padding: 0;
}

.inpuField {
  border: 1px solid #ccc !important;
  border-radius: 4px !important;


}

.btnFieldDate {
  min-width: -webkit-fill-available;
  min-height: 40px;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
}

/* MEDIA QUERIES */

@media (max-width: 800px) {
  .inpuField {
    padding: 6px 7px;

    /* padding-right:30.5vw; */

  }

  .logo {
    display: none;
  }

  .imagelogin img {
    height: 250px;
  }
}

@media (max-width: 500px) {

  .imagelogin img {
    height: 250px;
  }

  .nav-container {
    font-size: x-small;
  }

  .nav-cart {
    height: 25px;
  }
}

@media (max-width: 400px) {
  .nav-link {
    margin: 10px 0;
    padding: 10px 2;
  }

  .imagelogin img {
    height: 250px;
  }
}

@media (max-width: 325px) {
  .nav-link {
    margin: 10px 0;
    padding: 10px 1;
  }

  .imagelogin img {
    height: 250px;
  }

  .nav-cart {
    height: 14px;
  }
}

/* HOME */

.banner-image {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
      url("./images/nike2.jpg"); */
  background-size: cover;
  background-position: center;
  height: 100vh;
  border-radius: 20px;
  margin: 1rem;
  box-shadow: 10px 10px 10px rgba(14, 12, 12, 0.534);
}

.tagline {
  display: block;
  font-family: aileron, sans-serif;
  /* font-family: "Acme", sans-serif; */
  font-size: 30px;
  color: #fff;
  /* margin-top: 35px; */
  display: flex;
  padding: 10px;
  font-weight: 600;
  padding-left: 30px;
}

.tagline-para {
  display: flex;
  /* text-align: center; */
  /* margin: 0 auto; */
  justify-content: center;
  /* font-family: "Karla", sans-serif; */

  font-family: aileron, sans-serif;
  font-size: 22px;
  color: #0c0b0bfd;
}

.tagline-login {
  display: flex;
  /* width: 80%;
  text-align: left; */
  /* margin: 0 auto; */
  /* justify-content: left; */
  /* font-family: "Karla", sans-serif; */
  font-family: aileron, sans-serif;
  font-size: 1.03rem;
  color: #0c0b0bfd;
  line-height: 2;
}

.tagline-para-home {
  display: flex;
  /* width: 50%; */
  text-align: left;
  /* margin: 0 auto; */
  justify-content: left;
  font-family: aileron, sans-serif;
  /* font-family: "Karla", sans-serif; */
  font-size: 25px;
  color: #0c0b0bfd;
}

.about-button {
  display: inline-block;
  text-decoration: none;
  border: none;
  outline: none;
  color: white;
  background: #5d0137;
  margin: 15px;
  font-size: 22px;
  border-radius: 20px;
  padding: 10px 20px;

  cursor: pointer;
}

/* Button hover effects */
.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  transform: translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  transition-duration: 0.5s;
  transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  transform: scale(1.05);
}

/** Latest designs Home **/
.latest-designs {
  font-size: 20px;
  text-shadow: 1px 1px 2px #ffffff;
}

.latest-container {
  margin: 10px;
}

.line {
  display: inline-block;
  width: 20%;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
}

.home-products {
  margin: 10px 10px;
  text-decoration: none;
  border: 1px solid rgba(128, 128, 128, 0.24);
  border-radius: 20px;
  padding: 10px 0;
  box-shadow: 2px 2px 2px gray;
  font-size: 100%;
  width: 270px;
  background: white;
  height: 250px;
}

.home-products:hover {
  border: 1px solid rgba(14, 12, 12, 0.87);
}

.product-container h3 {
  font-size: 98%;
}

/* MEDIA QUERIES */

@media (max-width: 1500px) {

  .imagelogin img {
    height: 150px;
  }

  .tagline-login {
    font-size: larger;
  }
}

@media (max-width: 1000px) {
  .banner-image {
    height: 50vh;
  }

  .imagelogin {
    display: none;
  }
}

@media (max-width: 800px) {
  .banner-image {
    height: 50vh;
  }

  .imagelogin {
    display: none;
  }
}

@media (max-width: 700px) {
  .home-products {
    width: 200px;
    height: 150px;
    font-size: 70%;
  }

  .imagelogin {
    display: none;
  }

  .tagline {
    font-size: 50px;
  }

  .tagline-para {
    font-size: 18px;
  }

  .about-button {
    font-size: 18px;
  }
}

@media (max-width: 500px) {
  .line {
    width: 10%;
  }

  .imagelogin {
    display: none;
  }

  .latest-designs {
    font-size: 18px;
  }

  .tagline-para {
    font-size: 12px;
  }

  .tagline {
    font-size: 40px;
  }

  .about-button {
    font-size: 12px;
  }

  .banner-image {
    height: 40vh;
  }

  .imagelogin img {
    height: 250px;
  }
}

/* About */
.banner-image-question {

  width: 100%;
  border-radius: 5px;
  box-shadow: 10px 10px 10px gray;
  margin: 1rem;
}

.banner-image-about {
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid #f3f9f3;
  margin: 3rem;
  display: flex;

}

.banner-image-dashboard {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 15px rgba(128, 128, 128, 0.194);
  margin: 1rem;
  width: 100%;
}

.banner-image-home {
  background-color: #eaf5db;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  border-radius: 30px;
  box-shadow: 10px 10px 10px gray;
  margin: 1rem;
}

.resend-box {
  margin: 10px;
  padding: 10px;
  border: 1px solid #e6ecf1;
  display: flex;
  flex-direction: column;
  margin-top: 0px;
}

.banner-image-login {
  background-color: #eaf5db;
  /* background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  /* height: 100vh; */
  /* border-radius: 30px; */
  max-width: 100%;
  height: 100%;
  /* margin: 0 auto 2rem; */
}


/* MEDIA QUERIES */


@media (max-width: 900px) {

  .banner-image-about {
    height: 70vh;
  }


  .banner-image-home {
    height: 100%;
  }

  .banner-image-login {
    height: 100vh;

  }

  .imagelogin img {
    height: 250px;
  }

}

@media (max-width: 750px) {
  .banner-image-about {
    height: 70vh;
  }

  .banner-image-home {
    height: 100%;
  }

  .banner-image-login {
    height: 100vh;
  }

  .imagelogin img {
    height: 250px;
  }

}



.sub-main {
  margin: 10px;
  float: left;
  margin-bottom: 20px;
  margin-top: 20px;
}

.banner-content {
  flex: 1
}


/*Button Three*/
.button-three {
  position: relative;
  font-weight: 500;
  background-color: #0072BC;
  border-radius: 2px;
  color: #fff;
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;

  min-width: 200px;
  height: 40px;
  text-align: center;
  -webkit-transition-duration: 0.4s;
  /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
}

.button-three:hover {
  background: #62B05D;
  box-shadow: 0px 2px 10px 5px #97B1BF;
  color: #fff;
}


* {

  font-family: aileron, sans-serif;
}


/* Login Health Information  */
.heatlh-information {
  max-width: 1120px;
  margin: 0 auto 2rem;
}

.heatlh-information>div>h1 {
  font-size: 2rem;
  color: #525252;
  font-weight: 600;
}

.heatlh-information>div>h3 {
  font-size: 1.13rem;
  font-weight: 600;
  margin-bottom: 1rem;
  line-height: 1.6;
}

.links {
  width: 400px;
  padding: 10px;
}

.links>img {
  width: 50%;
}

nav>a {
  text-decoration: none;
  padding-left: 30px;
  padding-right: 30px;
  border: 1px solid #dde5eb;
  text-decoration: none;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #dde5eb !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.healthline-information>h1 {
  margin-bottom: 0.2em;
  font-size: 30px;
  font-weight: 700;
}

.healthline-support>h1 {
  margin-bottom: 0.2em;
  font-size: 30px;
  font-weight: 700;
}

.healthline-textarea {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 30px;
  background: #fff;
}

.login-input {
  display: flex;
}

.input-nmuber {
  display: flex;
  gap: 1rem;
}

.ant-modal-header {
  background: #0072BC !important;
  padding: 20px;
}

.ant-modal-content {
  padding: 0px !important;
}

.ant-modal-footer {
  padding: 20px;
}

.ant-modal-title {
  color: #fff !important;
  font-weight: 700 !important;
  font-size: 20px !important;
}

.ant-modal-close {
  color: #fff !important;
}

.About-header {
  display: flex;
  background: #f3f9f3;
  padding: 30px;
  border-radius: 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.30);
}

.About-header h3,
.About-header h2 {
  margin-bottom: 0px;
}

.About-content {
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 3rem;
  border: 1px solid #cccdcc;
  border-radius: 5px;
  margin-bottom: 50px;
}

.about {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 10px;
  flex: 2;
}

.sider {
  flex: 1 1 0;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.13);
  margin-right: 7px;
  max-width: 100px;
  /* position: absolute; */
  /* bottom: 90; */
  /* top: 0; */
  /* height: 100%; */
  gap: 3rem;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #f3f9f3;
  /* border-top-left-radius: 20px; */
  /* border-bottom-left-radius: 20px; */
}

.sider>a {
  text-decoration: none !important;
}

.navbar-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn-Next {
  width: 100px;
  align-self: flex-end;
  margin-right: 20px;
}

.pointer {
  cursor: pointer;
}

.custom-input {
  padding: 8px 11px !important;
}

.ant-select-selector {
  padding: 6px 11px !important;
  height: 40px !important;
}

@media (max-width: 900px) {

  .login-content {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    align-items: center;
  }

  .banner-content {
    display: flex;
    flex-direction: column;
    height: fit-content;

  }

  .banner-image-login {
    height: fit-content;
  }

  .healthline-textarea {
    max-width: 100%;
  }

  .login-area {
    max-width: 100%;
  }

  .images-container {
    flex-direction: column;
  }

  .tagline {
    font-size: 30px;
  }

  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

.download-image {
  width: 200px;
}

.download-image>img {
  width: 200px;
}

@media (max-width: 500px) {

  .download-image {
    width: 150px;
  }

  .download-image>img {
    width: 150px;
  }

  .flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

}

.ant-picker-input>input {
  cursor: pointer !important;
}

.main-body {
  display: flex;
  flex-direction: column;
  height: 100vh
}

.sign-up-form {
  padding: 30px;
  display: flex;
  justify-content: center
}

@media (max-width: 770px) {
  .content {
    flex-direction: column !important;
  }

  .sider {
    flex-direction: row;
    max-width: 100%;
    flex: 0;
    box-shadow: none !important;
    margin-right: 0px;
    padding: 15px;
    font-size: 14px;
  }

  .About-header {
    padding: 15px;
    box-shadow: none;
    justify-content: center;
  }
  .banner-image-question {
    width: 100%!important;
    height: 70vh!important;
    border-radius: 0px!important;
  }
  .table-row{
    padding-bottom: 15px;
  }
.ant-modal{
  margin-left: 17px!important;
}
  .sign-up-form {
    padding-top: 10px;
    padding-right: 10px;
  }

  .main-body {
    height: fit-content;
  }

  .Header_headerContainer {
    height: 70px;
    padding: 10px;
  }

  .tagline {
    font-size: 20px;
  }

  .ibcQoD {
    padding: 15px 25px !important;
  }
  .pincode-input-container{
    padding: 5px!important;
  }
  .pincode-input-text{
    height: 40px!important;
    width: 40px!important;
    border:1px solid #a7c8f1!important;
  }
  .text-center{
    margin-bottom: 15px;
    font-size: 14px;
  }
  .resend-box{
    margin-top: 0px;
  }

}

/* @media (max-width: 375px) {
  .content {
    flex-direction: column !important;
  }

  .sider {
    flex-direction: row;
    max-width: 100%;
    flex: 0;
    box-shadow: none !important;
    margin-right: 0px;
    padding: 15px;
    font-size: 14px;
  }

  .About-header {
    padding: 15px;
    box-shadow: none;
    justify-content: center;
  }
  .banner-image-question {
    width: 100%!important;
    height: 70vh!important;
    border-radius: 0px!important;
  }
  .table-row{
    padding-bottom: 15px;
  }
.ant-modal{
  margin-left: 17px!important;
}
  .sign-up-form {
    padding-top: 10px;
    padding-right: 10px;
  }

  .main-body {
    height: fit-content;
  }

  .Header_headerContainer {
    height: 70px;
    padding: 10px;
  }

  .tagline {
    font-size: 20px;
  }

  .ibcQoD {
    padding: 15px 25px !important;
  }

} */

@media (max-width: 899px) {
  .main-body {
    height: fit-content;
  }

}

@media (max-width: 767px) {
  .Header_headerContainer {
    height: 70px;
    padding: 10px;
  }
}
.pointer>img{
  border:1px solid #99c0f1;
  border-radius: 50%;
}
.pincode-input-text{
  border:1px solid #a7c8f1!important;
}

.main-cont{
	background-image: url(./assets/Images/background-image.jpg);
	background-repeat: no-repeat;
	/* height: 910px; */
	/* padding: 120px 0 120px 200px; */
	background-size: cover;
	object-fit: cover;
	background-position: right;
	width: 100%;
	/* float: left; */
  flex: 1;
  padding: 20px;
}

.internalheading{
	font-size: 34px;
	font-weight: bold;
	margin-bottom: 20px;
	width: 100%;
	float: left;
}

.content-text{
	width: 60%;
	float: left;
	font-size: 20px;
}
.input-area{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.content-text p{
	margin: 0;
}

.margin-bottom-20{
	margin-bottom: 20px !important;
}
.input-container{
	width: 100%;
	text-align: center;
}
.input-field{
	padding: 8px 12px;
	height: 32px;
	border: 1px solid #cdccc9;
	border-radius: 5px;
}

.btn-class{
	width: 100%;
	text-align: center;
}

.btn-primary{
	font-size: 24px;
    padding: 8px 12px;
    color: #fff;
    background-color: #0072bb;
    width: 220px;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 0 10px 0px rgb(183 192 206 / 20%);
    height: 65px;
    font-weight: bold;
	
}

@media (max-width: 1366px) {
	.main-cont{
		
		padding: 60px 0 60px 100px;
		background-size: cover;
		object-fit: cover;
		background-position: right;
	}
}
@media (max-width: 768px) {

	.header-top{
		padding: 20px;
		height: 105px;
	}
	.main-cont{
		padding: 20px;
		background-image: none;
		background-color: #f1f4eb;
		height: auto;
	}

	.main-heading-bg{
		padding: 20px;
	}
  .internalheading{
    font-size: 20px;
  }

	.content-text{
		width: 100%;
    font-size: 17px;
	}
}
